import { Modal, Button, Card } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import '../../Componentes/CSS/tarjeta.css'; // 

const ModalEmergencia = ({ showModal, handleClose }) => {
    return (
        <Modal show={showModal} onHide={handleClose} className="custom-modal" size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ color: '#000', textAlign: 'center', width: '100%' }}>
                    <div className="row justify-content-center align-items-center">
                        {/* Título */}
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                            <h4 className="text-bold" style={{ marginBottom: '0' }}>TELÉFONOS DE EMERGENCIA</h4>
                        </div>

                        {/* CENTRAL */}
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                            <p style={{ marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <strong>CENTRAL: </strong>
                                <Badge bg="warning" text="dark" style={{ marginLeft: '8px' }}>
                                    <i className="fas fa-phone-alt" style={{ marginRight: '8px' }}></i>
                                    <a href="tel:+515102080">510-2080</a>
                                </Badge>
                            </p>
                        </div>

                        {/* WHATSAPP */}
                        <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">
                            <p style={{ marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <strong>WHATSAPP: </strong>
                                <Badge bg="success" style={{ marginLeft: '8px' }}>
                                    <i className="fab fa-whatsapp" style={{ marginRight: '8px' }}></i>
                                    <a href="https://wa.me/51924170111">924170111</a>
                                </Badge>
                            </p>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>





            <Modal.Body closeButton className="datos">
                {/* Sección de Indicadores */}
                <div className="row">

                    {/* Sección 01 : INDICADORES CELESTES */}
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                                <i class="fas fa-female"></i>
                                                <i class="fas fa-child"></i>
                                    
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Violencia Familiar y Sexual</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero">
                                        <a href="tel:+51100">
                                            <h4>100</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                                <i class="fas fa-user-shield"></i>
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Policía Nacional del Perú</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero">
                                        <a href="tel:+51105">
                                            <h4>105</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                                <i class="fas fa-traffic-light"></i>                                 
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Policía de Carreteras</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero">
                                        <a href="tel:+51110">
                                            <h4>110</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                                <i class="fas fa-plus-circle"></i>
                                    
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Defensa Civil</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero">
                                        <a href="tel:+51115">
                                            <h4>115</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                                <i class="fas fa-virus"></i>
                                    
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Consulta Covid-19</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero">
                                        <a href="tel:+51113">
                                            <h4>113</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>  

                    </div>

                    {/* Sección 02 : INDICADORES ROJOS */}

                    <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta2">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                                <i class="fas fa-microphone"></i>

                                        </div>
                                        <div className="indicador-texto">
                                            <p>Mensajes de Voz Emergencias</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero2">
                                        <a href="tel:+51119">
                                            <h4>119</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta2">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                                <i class="fas fa-fire"></i>
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Bomberos del Perú</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero2">
                                        <a href="tel:+51116">
                                            <h4>116</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta2">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                                <i class="fas fa-hospital-symbol"></i>                                 
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Atención Médica Essalud</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero2">
                                        <a href="tel:+51117">
                                            <h4>117</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta2">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                                <i class="fas fa-ambulance"></i>
                                    
                                        </div>
                                        <div className="indicador-texto">
                                            <p>SAMU</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero2">
                                        <a href="tel:+51106">
                                            <h4>106</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>  

                    </div>

                    {/* Sección 03 : COMISARIAS */}

                    <div className="col-sm-4 col-md-4 col-lg-4 col-xs-12">

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta3">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                            <i class="fa-solid fa-shield-halved"></i>

                                        </div>
                                        <div className="indicador-texto">
                                            <p>San Cosme</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero3">
                                        <a href="tel:+980122499">
                                         <h4>980 122 499</h4>
                                        </a>                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta3">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                            <i class="fa-solid fa-shield-halved"></i>
                                        </div>
                                        <div className="indicador-texto">
                                            <p>La Victoria</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero3">
                                        <a href="tel:+980122376">
                                            <h4>980 122 376</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta3">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">                                       
                                            <i class="fa-solid fa-shield-halved"></i>                                
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Apolo</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero3">
                                        <a href="tel:+942090582">
                                            <h4>942 090 582</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                                <div className="indicador-tarjeta3">
                                    <div className="indicador-contenedor">
                                        <div className="indicador-icono">
                                        
                                         <i class="fa-solid fa-shield-halved"></i> 
                                    
                                        </div>
                                        <div className="indicador-texto">
                                            <p>Yerbateros</p>
                                        </div>
                                    </div>
                                    <div className="indicador-numero3">
                                        <a href="tel:+980122499">
                                            <h4>980 122 499</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>  

                    </div>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalEmergencia;